<template>
	<div class="goods-list" v-loading="loading">
		<div class="search_bread">
			<span class="keyword">{{$lang('title')}}</span>
		</div>
		<!-- 品牌过滤记录区 -->
		<div class="attr_filter" v-if="catewords">
			<el-tag type="info" v-if="choosedCategory" closable @close="colseCategory" effect="plain">
				<span v-if="choosedCategory" class="title">{{$lang('common.category')}}：</span>
				{{ choosedCategory.category_name }}
			</el-tag>
			<el-tag type="info" v-if="choosedBrand" closable @close="colseBrand" effect="plain">
				<span v-if="choosedBrand" class="title">{{$lang('common.brand')}}：</span>
				{{ choosedBrand.brand_name }}
			</el-tag>
		</div>
		<!-- 品牌属性筛选区 -->
		<template>
			<div class="category" v-if="brandList.length || child_categoryList.length">
				<!-- 分类列表 -->
				<div class="goods-class" v-if="child_categoryList.length>0">
					<div class="title">{{$lang('common.category')}}：</div>
					<ul>
						<li v-for="(item,index) in child_categoryList" :key="index"	@click="onChooseCategory(item)"><a>{{item.category_name}}</a></li>
					</ul>
				</div>
				
				<!-- 品牌 -->
				<div class="brand">
					<div class="table_head">{{$lang('common.brand')}}：</div>
					<div class="table_body">
						<div class="content">
							<ul>
								<li v-for="(item,index) in brandList" :key="index" @click="onChooseBrand(item)">
									<a>{{item.brand_name}}</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</template>
		<!-- 商品列表 -->
		<div class="list-wrap">
			<!-- 排序筛选区 -->
			<div>
				<div class="sort">
					<div class="item" @click="changeSort('')">
						<div class="item-name">{{$lang('common.comprehensive')}}</div>
					</div>
					<div class="item" @click="changeSort('sale_num')">
						<div class="item-name">{{$lang('common.sales')}}</div>
						<i v-if="filters.order === 'sale_num' && filters.sort === 'desc'" class="el-icon-arrow-down el-icon--down" />
						<i v-else class="el-icon-arrow-up el-icon--up" />
					</div>
					<div class="item" @click="changeSort('discount_price')">
						<div class="item-name">{{$lang('common.price')}}</div>
						<i v-if="filters.order === 'discount_price' && filters.sort === 'desc'" class="el-icon-arrow-down el-icon--down" />
						<i v-else class="el-icon-arrow-up el-icon--up" />
					</div>
					<!-- <div class="item-other">
						<el-checkbox label="包邮" v-model="is_free_shipping"></el-checkbox>
					</div> -->
					<!-- <div class="item-other">
						<el-checkbox label="自营" v-model="is_own"></el-checkbox>
					</div> -->
					<div class="input-wrap">
						<div class="price_range">
							<el-input :placeholder="$lang('common.lowest_price')" size="small" v-model="filters.min_price"></el-input>
							<span>—</span>
							<el-input :placeholder="$lang('common.highest_price')" size="small" v-model="filters.max_price"></el-input>
						</div>
						<el-button plain size="mini" @click="handlePriceRange">{{$lang('common.confirm')}}</el-button>
					</div>
				</div>
			</div>

			<div class="cargo-list" v-if="cargoList.length">
				<div class="goods-info">
					<div class="item" v-for="(item, index) in cargoList" :key="item.goods_id" @click="$router.pushToTab({ path: '/product-' + item.goods_id + '.html' })" style="position: relative;">
						<!-- 自定义标签 -->
						<div class="goods-diy diy-discount" v-if="item.price_label">
							<span>{{item.price_label}}</span>
						</div>
						<div class="goods-diy diy-stock" :style="{background:item.label_color}" v-if="item.stock_label">
							<span>{{item.stock_label}}</span>
						</div>
						<div class="goods-left-diy diy-own" v-if="item.own_label">
							<span><i class="iconfont icon-sandian"></i>{{item.own_label}}</span>
						</div>
						<img class="img-wrap" :src="$img(item.sku_image)" @error="imageError(index)" />
						<div class="price-wrap">
							<div class="price">
								<p>{{ siteInfo.price_unit }}</p>
								{{ showPrice(item) }}
								<div class="price-icon-wrap">
									<img v-if="item.member_price && item.member_price == showPrice(item)" :src="$img('public/uniapp/index/VIP.png')"/>
									<img v-else-if="item.promotion_type==1" :src="$img('public/uniapp/index/discount.png')"/>
								</div>
							</div>
							<div class="market-price">{{ siteInfo.price_unit + item.market_price }}</div>
						</div>
						<div class="goods-name" v-if="current_lang">
							<router-link :to="'/product-' + item.goods_id + '.html?lang='+current_lang" :title="item.goods_name" target="_blank">{{ item.goods_name }}</router-link>
						</div>
						<div class="goods-name" v-else>
							<router-link :to="'/product-' + item.goods_id + '.html'" :title="item.goods_name" target="_blank">{{ item.goods_name }}</router-link>
						</div>
						<div class="goods-selling">{{ item.introduction }}</div>
						<div class="comment-wrap" v-if="item.evaluate > 0">
							<el-rate v-model="item.evaluate_rate" disabled></el-rate>
							<span>{{ item.evaluate }} {{$lang('common.evaluate_num')}}</span>
						</div>
						<div class="comment-wrap" v-else></div>
						<div class="saling">
							<div class="sale-num">
								<span>{{ item.sale_num || 0 }}</span> {{$lang('common.payment_num')}}
							</div>
							<div class="promotion-type" v-if="item.promotion_type == 1">{{$lang('common.limit_discount')}}</div>
						</div>
					</div>
				</div>
				<div class="pager">
					<el-pagination background :pager-count="5" :total="total" :prev-text="$lang('common.prevtext')" :next-text="$lang('common.nexttext')" :current-page.sync="currentPage"
						:page-size.sync="pageSize" @size-change="handlePageSizeChange" @current-change="handleCurrentPageChange"
						hide-on-single-page></el-pagination>
				</div>
			</div>
			<div class="empty" v-else>
				<div class="ns-text-align">{{$lang('common.no_goods')}}</div>
			</div>
		</div>
	</div>
</template>

<script>
import { goodsLabelPage } from '@/api/rebate';
import { mapGetters } from 'vuex';
import { adList } from '@/api/website';
import goodslabel from '../goodslabel.js';

export default {
	name: 'new',
	components: {},
	data: () => {
		return {
			label: 'new',
			current_lang: '',
		};
	},
	mixins: [goodslabel],
	created() {
		this.current_lang = this.$route.query.lang || ""
	},
	methods: {
		showPrice(data){
			let price = data.discount_price;
			if(data.member_price && parseFloat(data.member_price) < parseFloat(price)) price = data.member_price;
			return price;
		}
	}
};
</script>
<style scoped>
	.comment-wrap /deep/ .el-rate__icon {
		margin-right: 0px;
	 }
</style>
<style lang="scss" scoped>
	@import '../goodslabel.scss';
</style>
