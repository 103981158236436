import http from "../utils/http"

/**
 * 商品列表
 * @param {object} params
 */
export function goodsPage(params) {
    return http({
        url: "/api/goodssku/rebate",
        data: params,
        forceLogin: true
    })
}
